import React from "react";
import { motion } from "framer-motion";
import LocalHeroImage from "@/assets/images/main/main_local.png";
import RectangleIcon from "@/assets/svg/main/rectangle.svg";
import RightButton from "@/assets/images/icon_arrow_right_blue.png";
import { useNavigate } from "react-router-dom";
import ChatIcon from "@/assets/svg/main/Chat.svg";
import useHoverAnimation from "@/hooks/useHoverAnimation";

const list = [
  {
    id: 0,
    title: "문제발굴",
    des: "지역의 고질적인 문제점, 활용되지 못한 특성 진단",
    link: "http://qubiton.xyz:8888/opinion/list.php?type=Q",
  },
  {
    id: 1,
    title: "문제해결",
    des: "진단된 문제를 활용한 아이디어 및 해결방안 제안",
    link: "http://qubiton.xyz:8888/opinion/list.php?type=A",
  },
  {
    id: 2,
    title: "지도로 검색하기",
    des: "작성된 게시물을 지도위에 지리적, 시각적으로 표시",
    link: "http://qubiton.xyz:8888/sub03/sub01.php",
  },
  {
    id: 3,
    title: "창업교육",
    des: "지역연계창업 및 다양한 창업교육 지원",
    link: "http://qubiton.xyz:8888/bbs/board.php?tbl=bbs41&category=10",
  },
  {
    id: 4,
    title: "소식",
    des: "기관별 성과와 자료 등 새로운 소식 확인 가능",
    link: "http://qubiton.xyz:8888/bbs/board.php?tbl=bbs51",
  },
];

const localContentsList = {
  id: 1,
  content:
    "로컬 실록지리지 개념을 바탕으로 지역 경제 활성화 방안을 제시합니다. 학생들은 특정 지역의 고유한 문화와 역사적 배경을 이해하고, 이를 지역 경제 발전에 어떻게 활용할 수 있을지 고민합니다. 특히, 창업과 지역 산업에 미치는 영향을 분석합니다.",
};

export default function LocalHeroContent({ data }) {
  const navigate = useNavigate();
  const animation = useHoverAnimation();

  return (
    <div className={"tab_content_wrapper"}>
      <div className={"nav_btn_box"}>
        <button className="nav_btn" onClick={() => navigate("/local/map")}>
          <span>바로가기</span>
          <img src={RightButton} alt={"스카우트 컨텐츠 바로가기"} />
        </button>
      </div>

      <div className={"main_content_box"}>
        <motion.div className={"content_img_box"} {...animation}>
          <img src={LocalHeroImage} alt={""} />
        </motion.div>

        <motion.div className="consortium_content_box">
          <p>
            <img src={RectangleIcon} alt={""} />
            <span>관련 기능</span>
          </p>

          <ul>
            {list.map((content) => (
              <motion.li key={content.id} className={"local_hero_list"} {...animation}>
                <a href={content.link}>
                  <span>
                    <span className={"local_title"}>{content.title}</span>
                    <span>{content.des}</span>
                  </span>
                  <button className="nav_btn">
                    <img src={RightButton} alt={"스카우트 컨텐츠 바로가기"} />
                  </button>
                </a>
              </motion.li>
            ))}
          </ul>
        </motion.div>
      </div>

      <motion.div className={"tab_text_box"} {...animation}>
        <img src={ChatIcon} />
        <div>
          <p style={{ marginBottom: "16px" }}>
            지역사회에서 발생하는 문제를 발굴 및 공유하여 실천적 선순환을 이끌어 내는 활동 공유
            플랫폼입니다.
          </p>
          <p>
            지역사회 문제 발굴부터 문제 해결까지 전국 단위의 연합을 통해 의견을 공유하고 발전시킬 수
            있습니다. 또한, 문제 발굴 및 해결 과정에서 특정 지역의 고유한 문화와 역사적 배경을
            이해하고, 지역특색을 접목한 문제 해결을 통해 지역경제 활성화를 도모합니다.
          </p>
        </div>
      </motion.div>

      {/*<SwiperContents list={localContentsList} />*/}
    </div>
  );
}
