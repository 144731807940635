import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./modules/auth/authSlice.js";
import dateSlice from "./modules/common/dateSlice.js";
import curriculumSlice from "./modules/lms/editCourse/curriculumSlice.js";
import surveySlice from "@/store/modules/lms/survey/surveySlice";
import baseInfoSlice from "@/store/modules/lms/editCourse/baseInfoSlice";
import managementModalSlice from "@/store/modules/lms/managementCourse/managementModalSlice";
import { IS_LOCALHOST } from "@/constants/common";
import universitySlice from "./modules/univ/univSlice.js";
import chatbotSlice from "@/store/modules/toolkit/chatbotSlice";

// store 연결해줘야됨
export default configureStore({
  reducer: {
    // 변수작명: user.reducer,
    authSlice: authSlice.reducer,
    dateSlice: dateSlice.reducer,
    curriculumSlice: curriculumSlice.reducer,
    surveySlice: surveySlice.reducer,
    baseInfoSlice: baseInfoSlice.reducer,
    managementModalSlice: managementModalSlice.reducer,
    universitySlice: universitySlice.reducer,
    chatbotSlice: chatbotSlice.reducer,
  },
  // 로컬호스트( npm run start || start:dev )에서만 작동
  devTools: IS_LOCALHOST,
});
