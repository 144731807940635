import "./quickMenu.css";

import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import IconApply from "@/assets/svg/icon_quickmenu_apply.svg";
import IconParticipation from "@/assets/svg/icon_quickmenu_participation.svg";
import IconPlan from "@/assets/svg/icon_quickmenu_plan.svg";
import IconWrite from "@/assets/svg/icon_quickmenu_write.svg";
import IconCompletion from "@/assets/svg/icon_quickmenu_completion.svg";
import Icon_ai from "@/assets/svg/icon_ai.svg";

import { ReactComponent as Icon_quick_arrow } from "@/assets/svg/btn_arrow_bracket_r.svg";

const menuList = [
  {
    id: 1,
    name: "컨소시엄 소개",
    to: "/about/consortium",
    icon: IconApply,
  },
  {
    id: 2,
    name: "프로그램 신청",
    to: "/education/list",
    icon: IconParticipation,
  },
  {
    id: 3,
    name: "BMC 작성",
    to: "/toolkit/bmc/list/write",
    icon: IconPlan,
  },
  {
    id: 4,
    name: "창업교육 콘텐츠",
    to: "/board/eduContents/researchRef?p=1",
    icon: IconWrite,
  },
  {
    id: 5,
    name: "AI 시장검증",
    to: "/toolkit/chatbot",
    icon: Icon_ai,
  },
  {
    id: 6,
    name: "공지사항",
    to: "/board/notice",
    icon: IconCompletion,
  },
];

const QuickMenu = () => {
  const { pathname } = useLocation();
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const quickRef = useRef(null);
  const [isScrollDown, setIsScrollDown] = useState(null); // 스크롤 방향 다운

  const MOVE_POINT = 50; // 이동할 스크롤 값
  let lastScrollY = 1; //이전 스크롤 값 저장
  const ignoreScrollEvent = useRef(false); // 스크롤 이벤트 무시 플래그

  const handleScroll = () => {
    if (ignoreScrollEvent.current) return;

    quickRef.current.classList.remove("on");
    const newScrollY = window.scrollY;

    // 스크롤 방향 감지 ( 현재스크롤 > 이전스크롤 )
    if (newScrollY > lastScrollY) {
      setIsScrollDown(true);
    } else {
      setIsScrollDown(false);
    }
    lastScrollY = newScrollY;
    if (!quickRef?.current) return;
    setTimeout(() => {
      if (!quickRef?.current) return;
      // transition을 이용한 animation
      quickRef.current.classList.add("on");
    }, 500);
  };

  const handleQuickMenuClick = () => {
    setIsScrollDown(null);
    setIsMenuVisible(!isMenuVisible);
    quickRef.current.style.setProperty("top", "50%");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!quickRef?.current) return;
    if (isScrollDown !== null) {
      const adjustedScroll = isScrollDown
        ? `calc(50% + ${MOVE_POINT}px)`
        : `calc(50% - ${MOVE_POINT}px)`;
      quickRef.current.style.setProperty("top", adjustedScroll);
      // console.log("적용되는 값 clac : ", adjustedScroll);
    }
  }, [isScrollDown]);

  useEffect(() => {
    setIsScrollDown(null);
    // 페이지 변경 시 스크롤 이벤트 무시 설정
    ignoreScrollEvent.current = true;

    // 일정 시간 후에 스크롤 이벤트 다시 활성화
    const timer = setTimeout(() => {
      ignoreScrollEvent.current = false;
    }, 200); // 200ms 동안 스크롤 이벤트 무시

    return () => clearTimeout(timer);
  }, [pathname]);

  return (
    <section ref={quickRef} className={`quickMenuContainer ${isMenuVisible ? "visible" : "hide"}`}>
      <ul className={`quickMenuList `}>
        {menuList.map((list) => (
          <li key={list.id}>
            <img src={list.icon} alt={`${list.name}`} />
            <Link className="quickMenuName" to={list.to}>
              {list.name}
            </Link>
          </li>
        ))}
        <div className="quickMenuButton">
          <button onClick={handleQuickMenuClick}>
            퀵 메뉴 버튼
            <Icon_quick_arrow
              stroke="#fff"
              width={20}
              height={20}
              className={`quickMenuImage ${isMenuVisible ? "rotated" : ""}`}
            />
          </button>
        </div>
      </ul>
    </section>
  );
};

export default QuickMenu;
