import "./globalTabSection.css";

/**@desc 특정 title을 가진 리스트를 tab으로 만들어주는 컴포넌트, 해당 텝 클릭 시 이벤트는 2가지로 나뉨.
 * @desc 1. 공통함수를 실행할 경우 commonFunc props 에 클릭 시 실행 될 공통함수를 넣는다.
 * ex) params에서 가져온 string을 통해 navigate로 컴포넌트를 이동하는 경우
 * @desc 2. 리스트별로 실행시킬 함수가 다른 경우 해당 tabList 내부 item마다 { clickFunc: willUseFunction() } 을 담아준다.
 * @param { Array } tabList { title: "my title", clickFunc: OptionalFunction() } 의 형식으로 title은 필수 clickFunc는 실행할 함수만 담는다
 * @param { any } commonFunc 클릭 시
 * @param { function } handleSetSelectedTab => item title뿐만 아니라 item 자체를 받을 때 사용
 * @param { string } selectedTab
 * @param { any } setSelectedTab
 * @param { "managementModal" } theme 테마를 지정하면 해당 스타일로 변경됨
 * @param { boolean } isDivider 하단영역 디바이더 여부
 * @param { CSSProperties } dividerStyle 하단영역 디바이더 여부
 * */
const GlobalTabSection = ({
  tabList = [],
  commonFunc,
  handleSetSelectedTab,
  selectedTab,
  setSelectedTab,
  theme,
  isDivider,
}) => {
  // 텝 클릭 함수
  const handleTabClick = (tabItem) => {
    if (handleSetSelectedTab) handleSetSelectedTab(tabItem);
    else setSelectedTab(tabItem.title);
    // 각 함수가 존재하면 실행
    if (commonFunc) commonFunc();
    if (tabItem.clickFunc) tabItem.clickFunc();
  };

  // tabList 가 존재할 때만 렌더링
  return (
    !!tabList?.length && (
      <ul className={`globalTabContainer ${theme || ""}`}>
        {tabList.map((item, idx) => (
          <li
            key={idx}
            className={`globalTabItem ${theme || ""} ${selectedTab === item.title && "selected"} ${
              selectedTab === item.value && "selected"
            }`}
            onClick={() => handleTabClick(item)}
          >
            {item.title}
          </li>
        ))}

        {/* 하단 divider 추가 */}
        {isDivider && <div className="global_tab_bottom_divider" />}
      </ul>
    )
  );
};

export default GlobalTabSection;
