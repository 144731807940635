import {useSearchParams} from "react-router-dom";

/**
 * @desc 1. get(key): 매개변수에 key를 넣어 특정 query의 value를 얻음
 * @desc 2. set(key, value): 매개변수에 key, value를 넣어 query를 세팅
 * @desc 3. remove(key): 매개변수에 key 넣어 query를 삭제
 * @desc 4. searchParams: 해당 훅이 가진 useSearchParams의 searchParams
 * @desc 5. setSearchParams: 해당 훅이 가진 useSearchParams의 setSearchParams
 *  */
const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // set Query string
  /**@desc queryString setting 함수이며 3번째 인자로 replace 추가 가능
   * @param {string} key 지정할 key
   * @param {string} value 지정할 value
   * @param {boolean?} replace 지정할 value
   *  */
  const set = (key, value, replace) => {
    searchParams.set(key, value);
    setSearchParams(searchParams, {replace});
  };

  // get Query string
  const get = (key) => searchParams.get(key);

  // delete Query string
  const remove = (key) => {
    searchParams.delete(key);
    setSearchParams(searchParams);
  };

  return {set, get, remove, searchParams, setSearchParams};
}

export default useQueryParams;