export default function MainUniversityConsortium({
  title = "",
  universityList = [],
  animate = false,
  isMobile,
  navigateToUniversity,
}) {
  return (
    <div className="home_third_university_consortium">
      {!isMobile && <div className="home_third_university_consortium_title">{title}</div>}
      <div
        className={`home_third_university_consortium_list ${animate ? "slideUp" : ""} ${
          universityList?.length % 2 == 0 ? "Even" : "Odd"
        }`}
      >
        {universityList?.map((university, idx) => (
          <div
            key={idx}
            className={`home_third_university_consortium_list_item`}
            onClick={() => navigateToUniversity(university)}
          >
            <img src={university.logo} alt="university_logo" />
            {university.fullname}
          </div>
        ))}
      </div>
    </div>
  );
}
