/**
 * 컴포넌트 다발적 사용으로 인한 참조 순환을 방지하기 위해
 * index.js에서 import해주어 참조 순환 방지
 * */

import ModalHeader from "./ModalHeader";
import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { COMMON_MOTION_TRANSITION } from "@/constants/common";

/**
 * @desc 스타일 지정하여 사용 가능한 모달 컴포넌트이며 width, height 를 반드시 지정해주어야 함<br>
 * includesClassName: 모달에 지정된 클래스에 모두 포함될 클래스 <br>
 * preventOverlayClose: 오버레이 클릭 시 모달이 닫히는 것을 막는 props <br>
 * theme: 현재는 기본과 미니 모달의 테마만 존재 (기본: "", 미니모달: mini)
 *
 * @param props props
 *
 * @param {string} props.includesClassName modal을 구성하는 모든 className에 추가될 className
 * @param {string} props.contentClassName 컨텐트 클래스네임 추가하기
 * @param {"mini" | "confirm"} props.theme (기본: "" | undefined, 미니모달: "mini", 컨펌모달: "confirm")
 * @param {any} props.setIsOpen 모달 open setState
 * @param {boolean} props.isOpen 모달 open state
 * @param {any} props.children 자식요소
 * @param {any} props.closeFunction close 함수 ( 내부에서 setState로 종료함수 포함 시 setIsOpen 없이도 모달 닫힘 )
 * @param {boolean} props.preventOverlayClose overlay를 클릭 시 모달 open/close 여부
 * @param {CSSProperties} props.overlayStyle overlay style 직접 inline style로 지정 가능
 * @param {boolean} props.isCustomScroll 커스텀 스크롤러 지정 여부
 * @param {boolean} props.showCloseIcon close icon show 여부
 * @param {string | ReactNode} props.modalTitle 모달 타이틀
 * @param {CSSProperties} props.modalContentWrapperStyle 컨텐트 랩퍼 인라인 스타일
 * @param {CSSProperties} props.modalContainerStyle 컨테이너 인라인 스타일
 *
 *  */
export default function Modal(props) {
  const {
    children,
    modalContainerStyle = {},
    modalContentWrapperStyle = {},
    overlayStyle = {},
    modalTitle,
    setIsOpen,
    isOpen,
    preventOverlayClose, // true인 경우 오버레이 클릭 시 모달 닫히는걸 막음
    closeFunction,
    contentClassName = "",
    includesClassName = "",
    isCustomScroll,
    theme = "",
    showCloseIcon = true,
  } = props;

  const overlay = useRef(null);

  // ESC 키다운 이벤트
  const onKeyDown = (e) => {
    if ((closeFunction || setIsOpen) && e.key === "Escape") {
      if (closeFunction) closeFunction();
      if (setIsOpen) setIsOpen(false);
    }
  };

  // 모달 close 함수
  const onClose = () => {
    if (isOpen) {
      if (closeFunction) closeFunction();
      if (setIsOpen) setIsOpen(false);
    }
  };

  // init effect
  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, { capture: false });
    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          ref={overlay}
          {...COMMON_MOTION_TRANSITION}
          className={`OverlaySection ${includesClassName} ${theme}`}
          style={overlayStyle}
          onClick={() => {
            // 오버레이 클릭 시 클로즈
            if (!preventOverlayClose) onClose();
          }}
        >
          <div
            className={`modal_container ${includesClassName} ${theme}`}
            style={modalContainerStyle}
            onClick={(e) => e.stopPropagation()}
          >
            <ModalHeader
              modalTitle={modalTitle}
              onClose={onClose}
              includesClassName={`${includesClassName} ${theme}`}
              showCloseIcon={showCloseIcon}
            />
            <div
              className={[`modal_content_wrapper ${includesClassName}`, theme].join(" ")}
              style={modalContentWrapperStyle}
            >
              <div
                className={[
                  `modal_content  ${includesClassName}`,
                  isCustomScroll ? "vertical_custom_scroll" : "",
                  contentClassName && contentClassName,
                  theme,
                ].join(" ")}
              >
                {children}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

// export default React.memo(Modal);
