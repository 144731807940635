import React, { useState, useRef, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel } from "swiper/modules"; // 최신 Swiper 모듈에서 가져오기
import "swiper/css"; // 최신 스타일 경로
import "swiper/css/mousewheel"; // Mousewheel 스타일
import "./MainHomePage.css";

import HomeFirst from "./home/first/HomeFirst";
import HomeSecond from "./home/second/HomeSecond";
import HomeThird from "./home/third/HomeThird";
import HomeFourth from "./home/fourth/HomeFourth";
import Footer from "@/components/common/footer/Footer";

import { scrollTop, isScroll, isScrollEnd } from "@/utils/common";
import useResponsive from "@/hooks/useResponsive";
import useQueryParams from "@/hooks/useQueryParams";
import Inspection from "@/components/common/inspection/Inspection";
import Popup from "@/components/common/popup/Popup";
import Cookies from "js-cookie";

const MainHomePage = () => {
  const isHandleRef = useRef(false);
  const swiperRef = useRef(null);
  const timeoutRef = useRef(null);
  const preventTimeoutRef = useRef(false);
  const mainRef = useRef(null);

  const PAGE_LIST = ["SCOUT", "콘텐츠", "컨소시엄", "커뮤니티"];
  const TRANSITION_SPEED = 1200;

  const [activeIndex, setActiveIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { isMobile, isTablet, isDesktop } = useResponsive();
  const params = useQueryParams();

  // refactor: 모달이 열려있을때 클릭되지 않도록 함수 종료 시킵니다~
  const handleSidebarClick = (index) => {
    // 모달이 열려있으면 클릭을 무시
    if (isMobile || !isDesktop || isModalOpen) return;

    if (swiperRef.current) {
      swiperRef.current.slideTo(index); // Swiper 슬라이드 변경
    }
  };

  // 휠 컨트롤 함수
  // refactor: 휠 컨트롤시 모달이 열려있으면 이전/다음 섹션으로 넘어가지 않도록 스크롤 막음
  const handleWheelSlide = (e) => {
    // !! 모달이 열려있으면 휠 이벤트 무시
    if (isMobile || !isDesktop || isModalOpen) return;

    const dom = document.scrollingElement;

    // 작동중이라면 실행불가
    if (isHandleRef.current) return;

    const y = e?.deltaY;

    // 스크롤 deltaY 가 숫자가 아닌 경우 취소
    if (isNaN(y)) return;

    if (swiperRef.current) {
      // ? 페이지 이동
      // 스크롤 다운
      if (y > 0) {
        // 마지막페이지 이거나 스크롤이 있는데 최하단이 아닌 경우에는 실행취소
        if (activeIndex === PAGE_LIST.length || (isScroll(dom) && !isScrollEnd(dom))) return;
        swiperRef.current.slideNext();
      }
      // 스크롤 업
      else {
        // 첫페이지 이거나 스크롤이 있는데 최상단이 아닌 경우에는 실행취소
        if (activeIndex === 0 || (isScroll(dom) && dom.scrollTop !== 0)) return;
        swiperRef.current.slidePrev();
      }

      // ? 정상 실행 됐다면 transition을 기다린 후 재작동 가능하도록 설정
      isHandleRef.current = true;

      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      timeoutRef.current = setTimeout(() => {
        isHandleRef.current = false;
      }, TRANSITION_SPEED);
    }
  };

  // ? 스와이퍼 조작 가능/불가능 컨트롤 함수
  const handleSwiperControlAble = () => {
    if (!isDesktop) return;

    if (swiperRef.current) {
      if (preventTimeoutRef.current !== null) {
        clearTimeout(preventTimeoutRef.current);
        preventTimeoutRef.current = null;
      }

      swiperRef.current.allowTouchMove = false;

      preventTimeoutRef.current = setTimeout(() => {
        swiperRef.current.allowTouchMove = true;
      }, TRANSITION_SPEED);
    }
  };

  const onChangeMainPageSlide = (swiper) => {
    setActiveIndex(swiper.activeIndex);
    params.set("page", swiper.activeIndex);
  };

  // ? slide 변경 중 control prevent
  useEffect(() => {
    handleSwiperControlAble();
  }, [activeIndex]);

  // init effect
  useEffect(() => {
    scrollTop();

    // clean up
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
      if (preventTimeoutRef.current !== null) {
        clearTimeout(preventTimeoutRef.current);
        preventTimeoutRef.current = null;
      }
    };
  }, []);

  // 새로고침, 이전 페이지로 이동 시 바라보고 있던 페이지로 이동
  useEffect(() => {
    if (swiperRef.current && params.get("page") && isDesktop) {
      // console.log("페이지이동", params.get("page"));
      // 비동기로 콜스택 뒤로 넘김
      setTimeout(() => {
        swiperRef.current.params.speed = 0;
        swiperRef.current.slideTo(params.get("page"));
        swiperRef.current.params.speed = TRANSITION_SPEED;
      }, 0);
    }
  }, [params.get("page"), swiperRef.current, isDesktop]);

  return (
    <>
      <main ref={mainRef} className={`main_home_page`} onWheel={handleWheelSlide}>
        {/* 사이드바 */}
        {isDesktop && (
          <div className="main_home_page_sidebar">
            {PAGE_LIST.map((item, index) => (
              <div
                key={index}
                className={`main_home_page_sidebar_item 
                ${activeIndex === index ? "active" : ""} 
                ${activeIndex === 1 && activeIndex !== index ? "non-active-white" : ""}`}
                onClick={() => handleSidebarClick(index)}
              >
                {item}
                {activeIndex === index && (
                  <div className="main_home_page_sidebar_item_underline"></div>
                )}
              </div>
            ))}
          </div>
        )}
        {/* Swiper 컨테이너 */}
        {isDesktop ? (
          <Swiper
            direction="vertical" // 세로 방향 슬라이더
            slidesPerView={"auto"} // 한 번에 하나의 슬라이드
            spaceBetween={0} // 슬라이드 간 간격
            className="main_home_page_swiper"
            speed={TRANSITION_SPEED}
            onSlideChange={onChangeMainPageSlide}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
            autoHeight={true}
          >
            <SwiperSlide className="main_home_page_slide">
              <HomeFirst />
            </SwiperSlide>
            <SwiperSlide className="main_home_page_slide">
              <HomeSecond setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
            </SwiperSlide>
            <SwiperSlide className="main_home_page_slide">
              <HomeThird />
            </SwiperSlide>
            <SwiperSlide className="main_home_page_slide">
              <HomeFourth />
            </SwiperSlide>
            <SwiperSlide className="main_home_page_footer_slide">
              <Footer />
            </SwiperSlide>
          </Swiper>
        ) : (
          <>
            <HomeFirst />
            <HomeSecond setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} />
            <HomeThird />
            <HomeFourth />
            <Footer />
          </>
        )}
      </main>
      {/* 점검 공지 */}
      {/* <Popup
        cookiesName={"inspection_notice"}
        modalTitle={"점검 공지"}
        modalStyle={{ top: "50%", left: "50%" }}
      >
        <Inspection Inspection_time={"2025-03-13(목) 18:00 ~ 24:00"}></Inspection>
      </Popup> */}
    </>
  );
};

export default MainHomePage;
