import "react-confirm-alert/src/react-confirm-alert.css";
import React from "react";
import MainProject from "./main/MainProject";
import MainEducation from "./main/MainEducation";
import "./main/main.css";
import MainConsortium from "./main/MainConsortium";
import MainLocalHeroes from "./main/MainLocalHeroes";
import MainUniversityAbout from "./main/MainUniversityAbout";
import scout from "@/assets/svg/main_scout.svg";
import Popup from "@/components/common/popup/Popup";

// import MainQuickMenu from "./main/MainQuickMenu";
// * 팝업 이미지
import noticeImage from "@/assets/images/popup/scout_main_poster.png";
import useResponsive from "@/hooks/useResponsive";
import Inspection from "@/components/common/inspection/Inspection";
import MainTopContent from "@/pages/main/renewal/top-content/MainTopContent";
import MainTabContent from "@/pages/main/renewal/main-content/MainTabContent";
import MainNotice from "@/pages/main/renewal/notice/MainNotice";

const MainPage = () => {
  const { isMobile } = useResponsive();
  return (
    <main>
      <div>
        <div>
          <div className="main_page_wrapper">
            <MainTopContent />
          </div>

          <div className={"tab_wrapper"}>
            <MainTabContent />
          </div>
        </div>

        <div className={"notice_wrapper"}>
          <MainNotice />
        </div>
        {/*<section className="mainContainer">*/}
        {/*  <div className="mainInner">*/}
        {/*    <h2 className="mainText">*/}
        {/*      scout*/}
        {/*      <img src={scout} alt="" data-aos="fade-up" data-aos-duration="1500" />*/}
        {/*    </h2>*/}

        {/*    <div className="main_bottom_text" data-aos="fade-up" data-aos-duration="1500">*/}
        {/*      <p>SCOUT 창업교육 혁신 선도대학</p>*/}
        {/*      <p>지역 기반의 효율적인 대학 창업교육 생태계 기반 구축</p>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*  <MainConsortium />*/}
        {/*</section>*/}
      </div>

      {/*<MainProject />*/}
      {/*<MainEducation />*/}
      {/*<MainLocalHeroes />*/}
      {/*<MainUniversityAbout />*/}

      {/* 재단 팝업 공지 */}
      {/* <Popup
        cookiesName={"popup_notice"}
        modalTitle={"학생 창업주간 공지"}
        modalStyle={isMobile ? {top: "50%", left: "50%"} : {top: "50%", left: "20%"}}
      >
        <img
          src={noticeImage}
          alt="공지이미지"
          className={"main_img"}
          style={isMobile ? {width: "100%"} : {width: "520px"}}
        />
      </Popup> */}

      {/* 점검 공지 */}
      {/*<Popup*/}
      {/*  cookiesName={"inspection_notice"}*/}
      {/*  modalTitle={"점검 공지"}*/}
      {/*  modalStyle={{ top: "50%", left: "50%" }}*/}
      {/*>*/}
      {/*  <Inspection Inspection_time={"2024-10-7(월) 12:00 ~ 13:00"}></Inspection>*/}
      {/*</Popup> */}
    </main>
  );
};

export default MainPage;
