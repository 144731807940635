import store from "@/store";
import { logout } from "@/store/modules/auth/authSlice";
import { getTokenCookies } from "@/utils/cookies";
import DOMPurify from "dompurify";

export const scrollTop = () => window.scrollTo(0, 0);

/** 스크롤이 끝났을 때 true 반환
 * @example isScrollEnd(document.scrollingElement);
 * */
export const isScrollEnd = (targetElement) => {
  const calc = Math.floor(targetElement.scrollHeight - targetElement.scrollTop);
  return calc - targetElement.clientHeight <= 0;
};

/** 스크롤이 존재하한다면 true 반환
 * @example isScroll(document.scrollingElement);
 * */
export const isScroll = (targetElement) =>
  targetElement.scrollHeight !== targetElement.clientHeight;

// 쿠키가 하나라도 없다면 로그아웃 처리
export const ifNonCookiesLogout = () => {
  const cookies = getTokenCookies();
  if (!cookies.accessToken || !cookies.refreshToken || !cookies.userInfo) {
    store.dispatch(logout());
  }
};

/**@desc textarea ref 요소를 넣으면 줄바꿈 시 자동으로 높이 조절
 * @param {MutableRefObject<any>} textareaRef
 * */
export const resizeTextarea = (textareaRef) => {
  if (textareaRef.current) {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  }
};

/**@desc 다중구조(중첩구조)를 가진 객체(배열포함)을 완벽하게 깊은 복사할 때 사용*/
export const jsonDeepCopy = (target) => {
  return JSON.parse(JSON.stringify(target));
};

/** padStart 2자리까지 앞에 0 채우는 함수 (ex: 3 -> "03") */
export const padFillZero = (target) => String(target).padStart(2, "0");

/**@desc innerHtml clean 함수 */
export const cleanDOM = (content) => DOMPurify.sanitize(content);

/**@desc 학번, 학과가 없는 경우 confirm 창을 띄워 정보 입력 유도 */
/**@param navigate useNavigate를 삽입 */
/**@return {boolean} confirm 여부를 return */
export const confirmCheckStudentMajor = (navigate) => {
  const userInfo = getTokenCookies()?.userInfo;

  // 교수자인 경우 validate 하지 않음
  if (userInfo?.cGubun === 1) return false;

  // 학생이고 학과 또는 학번이 없을 때
  if (
    (userInfo?.cGubun === 0 && !store.getState().authSlice.userDetailInfo?.c_major) ||
    !store.getState().authSlice.userDetailInfo?.c_student_id
  ) {
    if (
      confirm(
        "회원정보에 학과, 학번을 추가해주세요\n\n학과와 학번을 입력하지 않을 경우 수료증 및 다양한 컨텐츠에 정보가 표시되지 않습니다.\n\n확인을 누르면 회원정보 수정페이지로 이동합니다.",
      )
    ) {
      navigate("/auth/modify");
      return true;
    }
  }

  return false;
};

// 현재날짜의( day ) 마지막 시간 구하기
export const getEndOfDayDate = () => {
  const now = new Date();
  const endOfDayDate = new Date(now);
  endOfDayDate.setHours(23, 59, 59, 999); // 시간을 23:59:59로 설정
  return endOfDayDate;
};
