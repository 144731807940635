import React from "react";
import "./CardContents.css";
import RightButton from "@/assets/images/icon_arrow_right_blue.png";
import { regionDataHandling } from "@/utils/dataHandling";
import uuid from "react-uuid";
import useFormatDate from "@/hooks/useFormatDate";
import { truncateText } from "@/utils/validation";
import { ReactComponent as MarkerIcon } from "@/assets/svg/icon_map_marker.svg";

export default function CardContents({
  subTitle,
  title,
  description,
  region,
  className,
  button = true,
  handleNavLink,
  imgSrc,
  url,
  showModal,
  handleOpenModal, // 모달을 여는 함수 추가
  programData,
}) {
  const handleCardAction = () => {
    if (showModal) {
      handleOpenModal();
    } else if (button) {
      handleNavLink();
    } else if (url) {
      window.open(url, "_blank");
    }
  };

  console.log("내부 데이터 확인 region", region);

  return (
    <div className={className} onClick={handleCardAction}>
      <div className={"card_top_box"}>
        <span className={"card_sub_title"}>{subTitle}</span>
        {showModal && (
          <button className="nav_btn" onClick={handleNavLink}>
            <span>상세일정</span>
            <img src={RightButton} alt={"스카우트 컨텐츠 바로가기"} />
          </button>
        )}

        {button && (
          <button className="nav_btn" onClick={handleNavLink}>
            <span>바로가기</span>
            <img src={RightButton} alt={"스카우트 컨텐츠 바로가기"} />
          </button>
        )}

        {url && (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <button className="nav_btn">
              <span>바로가기</span>
              <img src={RightButton} alt={""} />
            </button>
          </a>
        )}
      </div>
      <div className={`card_title ${imgSrc ? "thumbnail_title" : ""}`}>
        {truncateText(programData ? programData.programName : title, 30)}
      </div>

      {imgSrc && (
        <div className={"thumbnail_box"}>
          <img src={imgSrc} alt={""} />
        </div>
      )}

      {programData ? (
        <div className="region_and_period_container">
          <div className={"regionTrans"}>
            <ul>
              {region &&
                region.map((item) => (
                  <li key={uuid()}>
                    <MarkerIcon />
                    {item}
                  </li>
                ))}
            </ul>
          </div>

          <dl className="period">
            <dt>신청기간</dt>
            <dd>{`${useFormatDate(programData?.proposeSdate, "yyyy.MM.dd")} ~ ${useFormatDate(
              programData?.proposeEdate,
              "yyyy.MM.dd",
            )}`}</dd>
          </dl>
        </div>
      ) : (
        <p className={"card_description"}>{description}</p>
      )}
    </div>
  );
}
