import "./SwiperCustomPagination.css";

//  . . . 클릭 스타일
export const SwiperPaginationClickStyle = ({
  item,
  currentCardPage,
  handlePaginationClick,
  maxItems = 3,
  isTextHide = false,
}) => {
  return (
    <>
      {item.length > 0 && (
        <div className={"custom_swiper_pagination_click_style"}>
          {item?.slice(0, maxItems).map((_, index) => (
            <button
              key={index}
              onClick={() => handlePaginationClick(index)}
              className={`swiper_pagination_bottom_button ${
                currentCardPage == index + 1 && "active"
              }`}
            >
              {!isTextHide && index + 1}
            </button>
          ))}
        </div>
      )}
    </>
  );
};

//  < 1/5 > 스타일
export const SwiperPaginationNumberStyle = ({
  currentCardPage,
  maxLength,
  handlePrev,
  handleNext,
}) => {
  return (
    <div className="custom_swiper_pagination_number_style">
      <button className="pagination_arrow" onClick={handlePrev}>
        &lt;
      </button>
      <span className="pagination_text">
        {currentCardPage} / {maxLength}
      </span>
      <button className="pagination_arrow" onClick={handleNext}>
        &gt;
      </button>
    </div>
  );
};
