// lazy 로딩 적용법
import { lazy, Suspense, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// 메인 페이지
import MainPage from "@/pages/MainPage";
import MainHomePage from "@/pages/MainHomePage";
// import EducationSchedule from "./pages/education/educationSchedule/EducationSchedule";
// ! test - 테스트 후 수정할 예정
import NotFound from "@/components/common/notFounds/NotFound";
// import ElasticSearchTest from "@/pages/test/ElasticSearchTest";
import NavigateTransition from "@/components/common/navigateTransition/NavigateTransition";
import { getTokenCookies } from "@/utils/cookies";
import { useDispatch, useSelector } from "react-redux";
import { getMyPageUserInfo } from "@/api/myPage";
import { setUserDetailInfo } from "@/store/modules/auth/authSlice";
import { confirmCheckStudentMajor } from "@/utils/common";
import OnlyGuestProvider from "@/components/provider/guest/OnlyGuestProvider";
import ScrollToTopOutlet from "@/components/common/scrollTop/ScrollToTopOutlet";

// 권한 라우터
const AuthPrivateElement = lazy(() => import("@/components/provider/private/AuthPrivateProvider"));
// 준비중인 페이지
const Prepare = lazy(() => import("@/components/common/prepare/Prepare"));
// auth
const UserLogin = lazy(() => import("@/pages/auth/UserLogin"));
const ClassumLogin = lazy(() => import("@/pages/auth/ClassumLogin"));
const UserSignUp = lazy(() => import("@/pages/auth/UserSignUp"));
const UserModify = lazy(() => import("@/pages/auth/MyPage/modify/UserModify"));
const FindUserInformation = lazy(() => import("@/pages/auth/findUserInfo/FindUserInformation"));
const UserMyPage = lazy(() => import("@/pages/auth/UserMyPage"));
// fnb
const Terms = lazy(() => import("@/pages/fnb/Terms"));
const Privacy = lazy(() => import("@/pages/fnb/Privacy"));
const FnbEmail = lazy(() => import("@/pages/fnb/FnbEmail"));
// about - 스카우트 , 컨소시엄 , 대학
const ScoutAbout = lazy(() => import("@/pages/about/ScoutAbout.jsx"));
const ScoutAboutRenewal = lazy(() => import("@/pages/about/ScoutAboutRenewal.jsx"));
const ConsortiumAbout = lazy(() => import("@/pages/about/ConsortiumAbout"));
const ConsortiumAboutLayout = lazy(() =>
  import("@/pages/about/ConsortiumAbout/layout/ConsortiumAboutLayout"),
);
const UniversityIntroduction = lazy(() =>
  import("@/pages/about/ConsortiumAbout/route/introduction/UniversityIntroduction"),
);
const UniversityHome = lazy(() => import("@/pages/about/ConsortiumAbout/route/UniversityHome"));
const UniversityProgramsList = lazy(() =>
  import("@/pages/about/ConsortiumAbout/route/program/list/UniversityProgramsList"),
);
const UniversityResearchList = lazy(() =>
  import("@/pages/about/ConsortiumAbout/route/research/list/UniversityResearchList"),
);
const UniversityResearchDetails = lazy(() =>
  import("@/pages/about/ConsortiumAbout/route/research/detail/UniversityResearchDetails"),
);
const UniversityVideoList = lazy(() =>
  import("@/pages/about/ConsortiumAbout/route/video/list/UniversityVideoList"),
);
const UniversityVideoDetails = lazy(() =>
  import("@/pages/about/ConsortiumAbout/route/video/UniversityVideoDetails"),
);
const UniversityGalleryList = lazy(() =>
  import("@/pages/about/ConsortiumAbout/route/gallery/components/list/UniversityGalleryList"),
);
const UniversityGalleryDetails = lazy(() =>
  import("@/pages/about/ConsortiumAbout/route/gallery/UniversityGalleryDetails"),
);
const UniversityMentoringList = lazy(() =>
  import("@/pages/about/ConsortiumAbout/route/mentoring/components/list/UniversityMentoringList"),
);
const UniversityPerformanceDetail = lazy(() =>
  import("@/pages/about/ConsortiumAbout/route/performance/detail/UniversityPerformanceDetail"),
);
const UniversityPerformanceAddPage = lazy(() =>
  import("@/pages/about/ConsortiumAbout/route/performance/UniversityPerformanceAddPage"),
);
const UniversityCompanyList = lazy(() =>
  import("@/pages/about/ConsortiumAbout/route/company/components/list/UniversityCompanyList"),
);
const UniversityCompanyDetails = lazy(() =>
  import("@/pages/about/ConsortiumAbout/route/company/UniversityCompanyDetails"),
);
const UniversityMember = lazy(() =>
  import("@/pages/about/ConsortiumAbout/route/member/UniversityMember"),
);

// education 창업교육 프로그램
// ! 신청하기 삭제
// const EducationApply = lazy(() =>
//   import("@/pages/education/educationCourse/educationApply/EducationApply.jsx"),
// );
// ! 신청확인 삭제
// const EducationCheck = lazy(() => import("@/pages/education/educationCheck/EducationCheck"));

const EducationParticipate = lazy(() =>
  import("@/pages/education/course/management/participate/Participate"),
);
const EducationLecture = lazy(() => import("@/pages/education/educationLecture/EducationLecture"));
const EditCourseLayout = lazy(() => import("@/pages/editCourse/layout/EditCourse.layout"));
const EditCourse = lazy(() => import("@/pages/editCourse/route/main/EditCourse"));
// const EducationListPage = lazy(() => import("@/pages/education/educationList/EducationListPage"));
const EducationListPage = lazy(() =>
  import("@/pages/education/educationListPage/EducationListPage"),
);
const EducationCourseInfo = lazy(() => import("@/pages/education/course/info/CourseInfo.jsx"));
// const TEST = lazy(() => import("@/pages/education/educationCourse/TEST"));
const EducationCourseManagement = lazy(() =>
  import("@/pages/education/course/management/ManagementCourse.jsx"),
);
const EducationInstruction = lazy(() =>
  import("@/pages/education/educationInstruction/EducationInstruction"),
);
// 창업교육 툴킷 - 비즈니스 모델 캔버스
// 목록
const BmcAbout = lazy(() => import("@/pages/toolkit/bmc/BmcAbout"));
const BMCListPage = lazy(() => import("@/pages/toolkit/bmc/BMCListPage"));
const BMCWritePage = lazy(() => import("@/pages/toolkit/bmc/BMCWritePage"));
const BMCModifyPage = lazy(() => import("@/pages/toolkit/bmc/BMCModifyPage"));
const BMCConnectedListPage = lazy(() => import("@/pages/toolkit/bmc/BMCConnectedListPage"));
// 캔버스
const BMCModelCanvas = lazy(() => import("@/pages/toolkit/bmc/BMCModelCanvas"));
const BusinessPlan = lazy(() => import("@/pages/toolkit/BusinessPlan"));
const AICustomerValidation = lazy(() => import("@/pages/toolkit/AICustomerValidation"));
const Chatbot = lazy(() => import("@/pages/toolkit/chatbot/Chatbot"));

const BusinessInstruction = lazy(() =>
  import("@/pages/toolkit/business/instruction/MyBusinessInstruction"),
);
const BMCInstruction = lazy(() =>
  import("@/pages/toolkit/bmc_renewal/instruction/MyBMCInstruction"),
);
const BusinessPlanList = lazy(() => import("@/pages/toolkit/business/MyBusinessPlanList"));
// const BusinessPlanDetail = lazy(() =>
//   import("@/pages/toolkit/business/detail/MyBusinessPlanDetail"),
// );
const BusinessPlanDetail = lazy(() =>
  import("@/pages/toolkit/business/detail/MyBusinessPlanDetail"),
);

const BusinessPlanModify = lazy(() =>
  import("@/pages/toolkit/business/modify/MyBusinessPlanModify"),
);

// 지역 문제해결 허브
const LocalMap = lazy(() => import("@/pages/local/LocalMap"));
// 게시판
const BoardLayout = lazy(() => import("@/pages/board/layout/BoardLayout"));
const BoardList = lazy(() => import("@/pages/board/route/commonBoard/CommonBoardList"));
const BoardDetails = lazy(() => import("@/pages/board/detail/BoardDetails"));
const BoardEdit = lazy(() => import("@/pages/board/edit/BoardEdit"));
const GalleryBoard = lazy(() => import("@/pages/board/route/galleryBoard/GalleryBoard"));

// 통합검색 todo: SSL 갱신 이슈 + 아직 미활용 예정으로 임시 비활성화
const ElasticSearch = lazy(() => import("@/pages/elasticSearch/ElasticSearch"));

function MainRoutes() {
  const { memberInfo } = useSelector((state) => state.authSlice);
  const { userDetailInfo } = useSelector((state) => state.authSlice);

  const cookies = getTokenCookies();

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 유저 상세정보 store state
  useEffect(() => {
    if (userDetailInfo) confirmCheckStudentMajor(navigate);
  }, [userDetailInfo]);

  // 페이지 첫 진입 시 로그인 상태일 때 학생인 경우 학번, 학과를 체크하고 둘 중 하나라도 없다면 confirm창을 띄워 안내
  // FIXME : 로그인 직후 창업교육 프로그램 > 프로그램 신청/개설에 들어가 "신청완료" 버튼을 클릭하면 해당 프로그램의 상세페이지로 이동하는 것이 아닌, Cannot read properties of null (reading 'c_univ') 오류가 뜹니다.
  // 찾아보니 로그인 직후 userDetailInfo가 null에서 갱신되지 않았고, 하단의 useEffect가 동작하지 않는 것 같아 userInfo, userDetailInfo, dispatch를 deps로 넣어주었습니다.
  useEffect(() => {
    // 로그인 상태이고 유저 상세 내용이 없을 때
    if (cookies?.accessToken && !userDetailInfo) {
      getMyPageUserInfo(cookies?.userInfo?.cNo)
        .then((res) => {
          // console.log("app fetch result", res);
          // ? store에 유저 상세정보 저장
          dispatch(setUserDetailInfo(res?.data.data[0]));
        })
        .catch((error) => {
          console.log("get my page user info error: ", error);
        });
    }
    // ComJavaApi.get("")
  }, [cookies?.accessToken]);

  return (
    <AnimatePresence mode="wait">
      <Suspense fallback={null}>
        <Routes location={location} key={location.pathname}>
          <Route element={<NavigateTransition />}>
            {/* 메인페이지 "/" */}
            <Route path="/" exact element={<MainHomePage />} />
            <Route path="/home" exact element={<MainPage />} />

            {/* 교육 - "/education" */}
            <Route path="/education">
              {/* index not found */}
              <Route index element={<NotFound />} />

              <Route
                path="participate/:c_member_no/:c_edu_no/:c_no/:c_project_no"
                element={<EducationParticipate />}
              />

              <Route
                path="lecture/:c_home/:c_edu_no/:c_edu_project_no/:c_member_no/:c_project_no/:c_team_no/:c_curi_no"
                element={<EducationLecture />}
              />

              {/* 프로그램 소개 페이지 */}
              <Route path="instruction" element={<EducationInstruction />} />

              {/* 글로벌 nav _ 1번 */}
              <Route path="course">
                {/* <= 제한적으로 보이게 변경해야됨 */}
                <Route index element={<EducationCourseInfo />} />
                {/* <Route path="apply" element={<EducationApply />} /> Title : 신청 하기 */}
                {/* <Route path="check" element={<EducationCheck />} /> Title : 신청 확인 */}
              </Route>

              {/* 교육 목록 */}
              <Route path="list" element={<AuthPrivateElement />}>
                <Route index element={<EducationListPage />} />
                {/* 교수자만 접근 가능하도록 라우팅 */}
                <Route element={<AuthPrivateElement isOnlyProfessor={true} />}>
                  <Route path="management" element={<EducationCourseManagement />} />
                  <Route path="editCourse/*" element={<EditCourseLayout />}>
                    <Route index element={<EditCourse />} />
                  </Route>
                </Route>
              </Route>

              {/* 교육 커리큘럼 - 상세일정 */}
              <Route
                path="schedule/:c_member_no/:c_edu_no/:c_no/:c_project_no"
                element={<EducationParticipate />}
              />
            </Route>

            {/* 창업교육 툴킷 - "/toolkit/bmc" */}
            <Route path="/toolkit">
              {/* index not found */}
              <Route index element={<NotFound />} />

              {/* bmc 소개 페이지 */}
              <Route path="bmcAbout" element={<BmcAbout />} />

              <Route path="bmc/instruction" element={<BMCInstruction />} />
              <Route path="bmc" element={<AuthPrivateElement />}>
                {/* BMC 작성하기 페이지 */}
                <Route path="list">
                  <Route index element={<BMCListPage />} />
                  <Route
                    path="connected/:c_edu_project_no/:c_project_no"
                    element={<BMCConnectedListPage />}
                  />
                  <Route path="write" element={<BMCWritePage />} />
                  <Route path="modify" element={<BMCModifyPage />} />
                  <Route path="modify/:c_team_no/:c_project_no" element={<BMCModifyPage />} />
                </Route>

                {/* BMC 캔버스 페이지 */}
                <Route path="model-canvas">
                  {/* index not found */}
                  <Route index element={<NotFound />} />

                  <Route path=":c_team_no/:c_project_no" element={<BMCModelCanvas />} />
                </Route>
              </Route>

              {/* ? ? 뭔지 모르겠지만 암튼 준비중인 페이지 */}
              <Route path="ai-customer" element={<AICustomerValidation />} />
              <Route path="biz-plan" element={<BusinessPlan />} />

              {/* 챗봇 */}
              <Route path="chatbot" element={<AuthPrivateElement />}>
                <Route index element={<Chatbot />} />
              </Route>

              {/* BMC 소개페이지 */}
              <Route path="myBusiness/instruction" element={<BusinessInstruction />} />

              {/* 나의 비즈니스 */}
              <Route path="myBusiness" element={<AuthPrivateElement />}>
                <Route index element={<BusinessPlanList />} />
                <Route path="detail/:bbiNo" element={<BusinessPlanDetail />} />
                <Route path="update/:bbiNo" element={<BusinessPlanModify />} />

                <Route path="newBusiness/" element={<BusinessPlanModify />} />
              </Route>
            </Route>

            {/* 로컬실록 지리지 */}
            <Route path="/local/map" element={<LocalMap />} />

            {/* 게시판 (멘토링 - 공지사항 - 고객지원) */}
            <Route path="/board" element={<BoardLayout />}>
              {/*
                레이아웃 방식으로 index element 가 별도로 필요없지만, /board 경로를 들어가면 빈페이지를
                반환하는 것을 막기 위해 index element 를 NotFound 페이지로 지정하여 돌려보냄
              */}
              <Route index element={<NotFound />} />

              {/* 공지사항, 멘토링, 문의 */}
              <Route path=":boardCategory">
                {/* 게시글 리스트 */}
                <Route index element={<BoardList />} />
                {/* 상세보기 */}
                <Route path=":boardId/:boardNo" element={<BoardDetails />} />
              </Route>

              {/* 게시판 작성 (현재 멘토링만 가용중) */}
              <Route path="create">
                {/* index not found */}
                <Route index element={<NotFound />} />
                {/* 현재 게시판 작성은 멘토링만 존재 */}
                <Route path=":createCategory" element={<BoardEdit />} />
              </Route>

              {/* 창업교육 컨텐츠 (회원 전용 게시판) */}
              <Route path="eduContents">
                {/* index not found */}
                <Route index element={<NotFound />} />
                {/* 연구자료 = commonBoard, 나머지 = galleryBoard */}
                <Route path="researchRef">
                  <Route index element={<BoardList />} />
                  <Route path=":boardId/:boardNo" element={<BoardDetails />} />
                </Route>

                <Route path=":eduContentsCategory">
                  <Route index element={<GalleryBoard />} />
                  <Route path=":boardId/:boardNo" element={<BoardDetails />} />
                </Route>
              </Route>
            </Route>

            {/* 통합 검색 todo: SSL 갱신 이슈 + 아직 미활용 예정으로 임시 비활성화 */}
            <Route path="/elastic" index element={<ElasticSearch />} />
            {/* <Route path="/elastic" index element={<Prepare />} /> */}

            {/* user */}
            <Route path="/auth">
              <Route element={<OnlyGuestProvider></OnlyGuestProvider>}>
                <Route path="login" element={<UserLogin />}></Route>
              </Route>
              <Route path="classum" element={<ClassumLogin />} />
              <Route path="signup" element={<UserSignUp />} />
              <Route path="modify" element={<UserModify />} />
              <Route path="findUserInfo" element={<FindUserInformation />} />

              {/* 마이페이지 */}
              <Route element={<AuthPrivateElement></AuthPrivateElement>}>
                <Route path="mypage" element={<UserMyPage />} />
              </Route>
            </Route>

            {/* fnb */}
            <Route path="/fnb">
              <Route path="terms" element={<Terms />} />
              <Route path="privacy" element={<Privacy />} />
              <Route path="email" element={<FnbEmail />} />
            </Route>

            {/* ! 준비중인 페이지를 위해서 넣어놨습니다. -하연  */}
            <Route path="/prepare" element={<Prepare />} />

            {/* todo: 테스트 이후 삭제 예정
            <Route path="/esTest" element={<ElasticSearchTest />} /> */}
          </Route>

          {/* 소개페이지 "/about" */}
          <Route path="about" element={<ScrollToTopOutlet />}>
            <Route path="scout" element={<ScoutAboutRenewal />} />
            <Route path="consortium">
              <Route index element={<ConsortiumAbout />} />
              <Route element={<ConsortiumAboutLayout />}>
                <Route path=":univNo" element={<UniversityIntroduction />} />
                <Route path=":univNo/home" element={<UniversityHome />} />
                <Route path=":univNo/programs" element={<UniversityProgramsList />} />
                <Route path=":univNo/contents">
                  <Route path="research" element={<UniversityResearchList />} />
                  <Route path="research/:ids" element={<UniversityResearchDetails />} />
                  <Route path="video" element={<UniversityVideoList />} />
                  <Route path="video/:ids" element={<UniversityVideoDetails />} />
                  <Route path="gallery" element={<UniversityGalleryList />} />
                  <Route path="gallery/:ids" element={<UniversityGalleryDetails />} />
                </Route>
                <Route path=":univNo/mentoring" element={<UniversityMentoringList />} />
                <Route path=":univNo/performance" element={<UniversityPerformanceDetail />} />
                <Route path=":univNo/performance/add" element={<UniversityPerformanceAddPage />} />
                <Route path=":univNo/company" element={<UniversityCompanyList />} />
                <Route path=":univNo/company/:ids" element={<UniversityCompanyDetails />} />
                <Route path=":univNo/member" element={<UniversityMember />} />
              </Route>
            </Route>
          </Route>

          {/* Not Found */}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </AnimatePresence>
  );
}

export default MainRoutes;
