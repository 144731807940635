export const REGION_MAP = {
  11: "수도권",
  12: "충청권",
  13: "호남제주권",
  23: "호남제주권",
  14: "대경강원권",
  24: "대경강원권",
  15: "동남권",
  25: "동남권",
};

// 권역 데이터(edu_field)를 string 배열의 형태로 return 하는 함수
export const regionDataHandling = (data) => {
  if (typeof data !== "string") return [];

  // 권역별로 잘라버리기
  const splitRegion = data?.split("|");

  // 소속대학과
  const regions = splitRegion
    .map((region) => REGION_MAP[region] || region)
    .filter((region) => region !== undefined && isNaN(Number(region)));

  // 중복 데이터 & 존재하지 않는 권역 넘버 제거
  const uniqueRegions = [...new Set(regions)];


  // uniqueRegions배열 객체를 순회하며 REGION_MAP에 없는 권역의 경우(=공동교수자의 대학명) 뒤로 보내도록 정렬렬
  const sortedRegions =  uniqueRegions.sort((a, b) => {
    const aIsRegion = Object.values(REGION_MAP).includes(a);
    const bIsRegion = Object.values(REGION_MAP).includes(b);
    
    if (aIsRegion && !bIsRegion) return -1;
    if (!aIsRegion && bIsRegion) return 1;
    return 0;
  });

  if (sortedRegions.length > 6) {
    return [...sortedRegions.slice(0, 6), "..."];
  }

  return sortedRegions;
};
// ["수도권","충청권"]  -> 이렇게 string을 갖는 배열로 return
