import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import Modal from "@/components/common/modals/Modal";
import useFormatDate from "@/hooks/useFormatDate";

import "swiper/css";
import "swiper/css/navigation";
import "./HomeSecond.css";
import calender_navy from "@/assets/svg/calender_navy.svg";

import EducationCuriDetail from "@/components/Lms/curriculum/modal/EducationCuriDetail";
import CardContents from "@/pages/main/renewal/top-content/components/CardContents";
import { getRecentlyContents, getRecentlyProgram } from "@/api/main/main";
import { getIntroduceListModal } from "@/api/lms";
import { getTokenCookies } from "@/utils/cookies";
import { format } from "date-fns";
import useResponsive from "@/hooks/useResponsive";
import {
  SwiperPaginationClickStyle,
  SwiperPaginationNumberStyle,
} from "@/components/common/swiperPagination/SwiperCustomPagination";
import MainPageProgram from "./components/MainPageProgram";
import MainPageEduContents from "./components/MainPageEduContents";
import EduProjectJoinProfessorTooltip from "@/components/common/tooltip/EduProjectJoinProfessorTooltip";

const HomeSecond = ({ isModalOpen, setIsModalOpen }) => {
  const { userInfo } = getTokenCookies();
  const isBtnHide = location.pathname === "/education/course";
  const navigate = useNavigate();

  const modalDataRef = useRef(null);
  const [modalContent, setModalContent] = useState(null);
  const [programCardData, setProgramCardData] = useState(null);
  const [contentsList, setContentsList] = useState([]);

  const [loading, setLoading] = useState(true);

  const fetchNewestProgram = async () => {
    setLoading(true); // 데이터 로드 시작
    try {
      const { data } = await getRecentlyProgram();
      console.log("fetchNewestProgramdata: ", data);
      setProgramCardData(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // 데이터 로드 완료
    }
  };

  const fetchNewestContents = async () => {
    setLoading(true); // 데이터 로드 시작
    try {
      const { data } = await getRecentlyContents();
      console.log("fetchNewestContentsData: ", data);
      setContentsList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // 데이터 로드 완료
    }
  };

  const fetchModalContent = async (modalData) => {
    console.log("=== fetchModalContent 호출됨 ===");
    console.log("programCardData: ", programCardData);
    console.log("isModalOpen: ", isModalOpen);
    console.log("modalData: ", modalData);
    if (programCardData && isModalOpen && modalData) {
      console.log("실행됨!!!!!!!!!!!!!!!!!!!!");
      try {
        const { data } = await getIntroduceListModal({
          projectNo: modalData.projectNo,
          eduNo: modalData.eduNo,
        });
        console.log(data);
        setModalContent(data);
      } catch (err) {
        console.error(err);
      }
    } else {
      console.log("조건 불충족으로 fetchModalContent 실행 안됨.");
    }
  };

  const handleOpenModal = async (modalData) => {
    if (loading) {
      console.error("Loading data, please wait.");
      return; // 로딩 중일 때는 함수 종료
    }

    // programCardData가 아직 로드되지 않았을 때
    if (!programCardData) {
      console.error("Program data is not loaded yet.");
      return; // 데이터가 없으면 함수 종료
    }

    const project_no = modalData?.projectNo || null;
    const edu_no = modalData?.eduNo || null;

    // project_no 또는 edu_no가 없을 때
    if (!project_no || !edu_no) {
      console.error("Project number or education number is missing.");
      return;
    }

    console.log("모달 열겠음!!!");
    setModalContent(null);
    modalDataRef.current = modalData;
    setIsModalOpen(true); // 모달 열기
  };

  const handleApplyList = () => {
    if (new Date(modalContent?.modalBaseData?.proposeEdate).getTime() < new Date().getTime())
      return;

    if (!userInfo?.cNo && confirm("로그인이 필요한 페이지입니다. 로그인 페이지로 이동합니다.")) {
      navigate("/auth/login");
    }
    if (userInfo?.cNo && confirm("신청을 위해 프로그램 신청 페이지로 이동합니다.")) {
      navigate("/education/list");
    }
  };

  useEffect(() => {
    fetchNewestProgram(); // 컴포넌트가 마운트될 때 호출
    fetchNewestContents(); // 컴포넌트가 마운트될 때 호출
  }, []);

  useEffect(() => {
    if (isModalOpen && modalDataRef.current) {
      fetchModalContent(modalDataRef.current);
    }
  }, [isModalOpen, modalDataRef.current]);

  return (
    <div className="home_second_wrapper">
      {loading ? (
        <div>...loading</div>
      ) : (
        <div className="home_second_contents_wrapper">
          <MainPageProgram
            programCardData={programCardData}
            handleOpenModal={handleOpenModal}
          ></MainPageProgram>

          <MainPageEduContents contentsList={contentsList}></MainPageEduContents>
        </div>
      )}

      {/* // ! EducationCardModal 로 변경 할 수 있는지 모바일 적용 후 추후 검토 */}
      {/* 모달 추가 */}
      {isModalOpen && (
        <Modal
          isCustomScroll
          includesClassName="course_info"
          setIsOpen={setIsModalOpen}
          isOpen={isModalOpen}
          modalTitle="상세일정"
        >
          <div className="eduModal_container">
            <span>{modalContent?.modalBaseData?.eduType}</span>
            <h3>{modalContent?.modalBaseData?.eduName}</h3>

            <p className="subTitle">
              {/* 권역, 대학교 */}
              <span>
                {`${modalContent?.modalBaseData?.educatorFieldName} ${modalContent?.modalBaseData?.educatorUnivName}`}
              </span>

              {/* 교수자 이름 */}
              <span className="edu_card_modal_professor_name">
                {modalContent?.modalBaseData?.educatorName} 교수
              </span>

              {/* 공동 교수자 카운트 ( 교수자 툴팁 ) */}
              {!!modalContent?.joinEducatorInfo?.cnt && (
                <span data-tooltip-id="edu_project_join_professor">
                  {"외 "}
                  <span className="edu_card_modal_join_professor_cnt">
                    {modalContent?.joinEducatorInfo?.cnt}명
                  </span>
                </span>
              )}
            </p>

            <div className="eduModal_contents">
              <div className="studentInfo">
                {/* 인원수 div 없애면서 인라인 스타일로 마진 값 없앰 */}
                <div className="studyPeriod" style={{ marginLeft: "0px" }}>
                  <img src={calender_navy} alt="calender_navy" />
                  <p>신청기간</p>
                  <span>{`${useFormatDate(
                    modalContent?.modalBaseData?.proposeSdate,
                    "yyyy.MM.dd",
                  )} ~ ${useFormatDate(
                    modalContent?.modalBaseData?.proposeEdate,
                    "yyyy.MM.dd",
                  )}`}</span>
                </div>
                <div className="studyPeriod" style={{ marginLeft: "0px" }}>
                  <img src={calender_navy} alt="calender_navy" />
                  <p>학습기간</p>
                  <span>{`${useFormatDate(
                    modalContent?.modalBaseData?.studySdate,
                    "yyyy.MM.dd",
                  )} ~ ${useFormatDate(
                    modalContent?.modalBaseData?.studyEdate,
                    "yyyy.MM.dd",
                  )}`}</span>
                </div>

                {/* 교수자일 경우에만 신청하기 버튼 삭제 */}
                {/* proposeEdate가 넘었을 때 신청하기 버튼 disabled */}
                {/* new Date(curiData.modalBaseData.proposeEdate가).getTime() < new Date().getTime() */}
                {isBtnHide && userInfo?.cGubun !== 1 && (
                  <button
                    className={
                      new Date(modalContent?.modalBaseData?.proposeEdate).getTime() <
                      new Date().getTime()
                        ? "modal_apply_btn"
                        : "modal_disabled_btn"
                    }
                    onClick={handleApplyList}
                  >
                    신청하기
                  </button>
                )}
              </div>
            </div>

            {/* Divider */}
            <div className="edu_card_modal_divider" />

            {/* 수료기준 - 고정값*/}
            <div className="CompletionCriteria">
              <dl className="description_box">
                <dt>수료기준</dt>
                <dd>
                  <ul>
                    <li>
                      <span>온라인 교육 :</span> 학습 기간 내에 교육 영상의 80% 이상 시청해야
                      출석으로 인정됩니다.
                    </li>
                    <li>
                      <span>오프라인 교육 :</span> 공지 확인 후 오프라인 교육에 참여해야 출석으로
                      인정됩니다.
                    </li>
                    <li>
                      <span>온오프 혼합교육 :</span>
                      공지 확인 후 오프라인 교육에 참여해야 출석으로 인정됩니다.
                    </li>
                    <li>
                      <span>실시간 화상 교육 :</span> 해당 실시간 화상 교육에 참여해야 출석으로
                      인정됩니다.
                    </li>
                    <li>
                      <span>* 수료 기준은 프로그램마다 상이할 수 있습니다.</span>
                    </li>
                  </ul>
                </dd>
              </dl>
              <dl className="description_box">
                <dt>수료증</dt>
                <dd>
                  <ul>
                    <li>모든 강좌의 수료기준 충족 시 수료증 출력이 가능합니다.</li>
                  </ul>
                </dd>
              </dl>
            </div>

            {/* 프로그램 내용 */}
            <EducationCuriDetail curiData={modalContent} />

             {/* 교수자 명단 툴팁 */}
            {!!modalContent?.joinEducatorInfo?.list?.length && (
              <EduProjectJoinProfessorTooltip listData={modalContent?.joinEducatorInfo?.list} />
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default HomeSecond;
