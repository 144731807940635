import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ComJavaApi } from "@/api";
import "./HomeFourth.css";
import { ReactComponent as AddButtonIcon } from "@/assets/svg/add_button.svg";
import { format } from "date-fns";
import { truncateText } from "@/utils/validation";
import useResponsive from "@/hooks/useResponsive";

const HomeFourth = () => {
  const defaultImgSrc = `${process.env.REACT_APP_ADMIN_FILE_URL}upload/board/videoThumbnailDefault1.png`;

  const navigate = useNavigate();

  const [galleryData, setGalleryData] = useState([]);
  const [videoData, setVideoData] = useState([]);
  const [noticeData, setNoticeData] = useState([]);
  const { isMobile } = useResponsive();

  const getGalleryData = async () => {
    const body = {
      pageNum: 1,
      keyword: "",
      region: "",
      boardNo: 322,
    };

    try {
      const res = await ComJavaApi.post("boardlist", body);

      if (res) {
        const data = res.data;
        const fetchedData = data.data.slice(0, 2);
        setGalleryData(fetchedData);
      }
    } catch (e) {
      console.log("에러", e);
    }
  };

  const getVideoData = async () => {
    const body = {
      pageNum: 1,
      keyword: "",
      region: "",
      boardNo: 41,
    };

    try {
      const res = await ComJavaApi.post("boardlist", body);

      if (res) {
        const data = res.data;
        const fetchedData = data.data.slice(0, 2);
        setVideoData(fetchedData);
      }
    } catch (e) {
      console.log("에러", e);
    }
  };

  const getNoticeData = async () => {
    const body = {
      pageNum: 1,
      keyword: "",
      region: "",
      boardNo: 40,
    };

    try {
      const res = await ComJavaApi.post("boardlist", body);

      if (res) {
        const data = res.data;
        const fetchedData = data.data.slice(0, 3);
        setNoticeData(fetchedData);
      }
    } catch (e) {
      console.log("에러", e);
    }
  };

  const navigateToGallery = () => {
    navigate("/board/eduContents/gallery?p=1");
  };
  const navigateToGalleryDetail = (board_id) => {
    navigate(`/board/eduContents/gallery/${board_id}/322`);
  };

  const navigateToVideo = () => {
    navigate("/board/eduContents/videoRef?p=1");
  };
  const navigateToVideoDetail = (board_id) => {
    navigate(`/board/eduContents/videoRef/${board_id}/41`);
  };

  const navigateToNotice = () => {
    navigate("/board/notice?p=1");
  };

  const navigateToNoticeDetail = (board_id) => {
    navigate(`/board/notice/${board_id}/40`);
  };

  useEffect(() => {
    getGalleryData();
    getVideoData();
    getNoticeData();
  }, []);

  return (
    <div className="home_fourth_wrapper">
      <div className="home_fourth_contents_wrapper">
        <div className="home_fourth_contents_top_title">
          <h3>Community</h3>
        </div>

        {/* 상단에 컨텐츠 두 개 */}
        <div className="home_fourth_contents_edu_contents">
          <div className="home_fourth_contents_edu_contents_wrapper">
            {/* 갤러리 */}
            <div className="home_fourth_contents_gallery">
              {/* 상단부 */}
              <div className="home_fourth_contents_gallery_top">
                <div className="home_fourth_contents_gallery_title">갤러리</div>
                <div className="home_fourth_contents_gallery_sub" onClick={navigateToGallery}>
                  <div className="home_fourth_contents_gallery_more">더보기</div>
                  <div className="home_fourth_contents_gallery_add">
                    <AddButtonIcon className="home_fourth_plus_button" />
                  </div>
                </div>
              </div>
              {/* 하단부(내용) */}
              <div className="home_fourth_contents_gallery_item">
                {galleryData.map((item, idx) => (
                  <div
                    key={idx}
                    className="home_fourth_contents_gallery_item_card"
                    onClick={() => navigateToGalleryDetail(item.c_no)}
                  >
                    <img
                      src={`${process.env.REACT_APP_ADMIN_FILE_URL}upload/board/${item.c_file[0]}`}
                      alt="gallery"
                      onError={(e) => {
                        e.target.src = defaultImgSrc;
                      }}
                    />
                    <div className="hover_text">
                      <span className="hover_text_title">{truncateText(item.c_title, 30)}</span>
                      {/*<span className="hover_text_date">{format(item.c_date, "yyyy.MM.dd")}</span>*/}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* 동영상 자료 */}
            <div className="home_fourth_contents_video">
              {/* 상단부 */}
              <div className="home_fourth_contents_video_top">
                <div className="home_fourth_contents_video_title">동영상 자료</div>
                <div className="home_fourth_contents_video_sub" onClick={navigateToVideo}>
                  <div className="home_fourth_contents_video_more">더보기</div>
                  <div className="home_fourth_contents_video_add">
                    <AddButtonIcon className="home_fourth_plus_button" />
                  </div>
                </div>
              </div>

              {/* 하단부(내용) */}
              <div className="home_fourth_contents_video_item">
                {videoData.map((item, idx) => (
                  <div
                    key={idx}
                    className="home_fourth_contents_video_item_card"
                    onClick={() => navigateToVideoDetail(item.c_no)}
                  >
                    <img
                      src={`${process.env.REACT_APP_ADMIN_FILE_URL}upload/board/${item.c_file[0]}`}
                      alt="video"
                      onError={(e) => {
                        e.target.src = defaultImgSrc;
                      }}
                    />
                    <div className="hover_text">
                      <span className="hover_text_title">{truncateText(item.c_title, 30)}</span>
                      {/*<span className="hover_text_date">{format(item.c_date, "yyyy.MM.dd")}</span>*/}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* 공지사항 (하단 한 개) */}
        <div className="home_fourth_contents_notice">
          {/* 상단부 */}
          <div className="home_fourth_contents_notice_top">
            <div className="home_fourth_contents_notice_title">공지사항</div>
            <div className="home_fourth_contents_notice_sub" onClick={navigateToNotice}>
              <div className="home_fourth_contents_notice_more">더보기</div>
              <div className="home_fourth_contents_notice_add">
                <AddButtonIcon className="home_fourth_plus_button" />
              </div>
            </div>
          </div>

          {/* 하단부(내용) */}
          <div className="home_fourth_contents_notice_item">
            {noticeData.map((item, idx) => (
              <div
                key={idx}
                className="home_fourth_contents_notice_item_card"
                onClick={() => navigateToNoticeDetail(item.c_no)}
              >
                {!isMobile && <div className="notice_item_kind">공지사항</div>}
                <div className="notice_item_title">{truncateText(item.c_title, 45)}</div>
                <div className="notice_item_date">{format(item.c_date, "yyyy.MM.dd")}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeFourth;
