import useResponsive from "@/hooks/useResponsive";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MarkerIcon } from "@/assets/svg/icon_map_marker.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { SwiperPaginationClickStyle } from "@/components/common/swiperPagination/SwiperCustomPagination";
import { Navigation } from "swiper/modules";
import { useRef, useState } from "react";

export default function MainPageEduContents({ contentsList = [] }) {
  const swiperRef = useRef(null);
  const navigate = useNavigate();

  const [currentCardPage, setCurrentCardPage] = useState(1);

  const { isMobile } = useResponsive();

  const navigateToContents = (content) => {
    console.log("content: ", content);
    if (content.boardNo === 332) {
      navigate(`/board/eduContents/researchRef/${content.boardId}/332`);
    } else if (content.boardNo === 41) {
      navigate(`/board/eduContents/videoRef/${content.boardId}/41`);
    } else if (content.boardNo === 322) {
      navigate(`/board/eduContents/gallery/${content.boardId}/322`);
    }
  };

  const handlePaginationClick = (index) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
      setCurrentCardPage(index);
    }
  };

  const updateCurrentPage = (swiper) => {
    // `realIndex`로 현재 페이지를 계산
    const realIndex = swiper.realIndex;
    setCurrentCardPage(realIndex + 1);
  };

  return (
    <div className="home_second_education_contents">
      <div className="home_second_education_contents_wrapper">
        <div className="home_second_education_contents_left">
          <div className="home_second_education_contents_title">창업 교육 컨텐츠</div>
          <div className="home_second_education_contents_description">
            해당 대학의 창업교육 자료 및 콘텐츠를 {!isMobile && <br />}
            확인 할 수 있습니다.
          </div>
        </div>
        {!isMobile && (
          <div className="home_second_education_contents_right">
            <div className="home_second_education_list">
              <ul className="home_second_education_list_ul">
                <li className="home_second_education_list_li">
                  {contentsList?.map((content, index) => (
                    <div
                      key={content.boardId}
                      className={`home_second_education_item`}
                      onClick={() => navigateToContents(content)}
                    >
                      <div className="home_second_education_info">
                        <div className="home_second_education_list_li_kind">
                          <span className="home_second_education_kind region">
                            <MarkerIcon />
                            {content.fieldName}
                          </span>
                          <span className="home_second_education_kind">{content.univName}</span>
                        </div>
                        <div className="home_second_education_list_li_title">{content.title}</div>
                      </div>
                      <div className="home_second_education_list_li_created_at">
                        {format(content.createDate, "yyyy.MM.dd")}
                      </div>
                    </div>
                  ))}
                </li>
              </ul>
            </div>
          </div>
        )}
        {isMobile && (
          <div>
            {contentsList?.length > 0 && (
              <Swiper
                modules={[Navigation]}
                loop={true}
                slidesPerView={isMobile ? 1 : 3} // or breakpoints
                slidesPerGroup={1}
                // loopedSlides={5}
                spaceBetween={30}
                onSwiper={(swiper) => {
                  swiperRef.current = swiper; // Swiper 인스턴스를 참조에 저장
                }}
                onSlideChange={(swiper) => {
                  updateCurrentPage(swiper);
                }}
              >
                {contentsList?.length > 0 &&
                  contentsList?.map((content, idx) => (
                    <SwiperSlide key={idx}>
                      <div
                        key={content.boardId}
                        className={`home_second_education_item`}
                        onClick={() => navigateToContents(content)}
                      >
                        <div className="home_second_education_info">
                          <div className="home_second_education_list_li_kind">
                            <span className="home_second_education_kind region mobile">
                              {content.fieldName}
                            </span>
                            <span className="home_second_education_kind mobile">
                              {content.univName}
                            </span>
                          </div>
                          <div className="home_second_education_list_li_title">{content.title}</div>
                        </div>
                        <div className="home_second_education_list_li_created_at">
                          {format(content.createDate, "yyyy.MM.dd")}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}
            <SwiperPaginationClickStyle
              item={contentsList}
              maxItems={5}
              currentCardPage={currentCardPage}
              handlePaginationClick={handlePaginationClick}
              isTextHide
            />
          </div>
        )}
      </div>
    </div>
  );
}
