import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getTokenCookies } from "@/utils/cookies";
import { alarmDisconnect } from "@/api/alarm";
import { logoutUser } from "@/api/auth";
import { logout } from "@/store/modules/auth/authSlice";

const ISNTITUTION_CODE = "scout";
// gnb snb 랜더링
const HeaderListItem = ({
  headerItem,
  idx,
  isMobile,
  isTablet,
  accordion,
  handleSub,
  handleGnb,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMentoringClick = async (e) => {
    const { accessToken, userInfo } = getTokenCookies();
    if (!accessToken || userInfo.cUnivNo === null) {
      e.preventDefault();
      alert("로그인이 필요합니다.");
      try {
        const requestData = {
          cNo: userInfo?.cNo,
          cName: userInfo?.cName,
        };

        const token = accessToken;

        // 1. 알람 SSE 연결 해제
        const alarmResponse = await alarmDisconnect(userInfo?.cNo, token);

        // eslint-disable-next-line
        const { data } = await logoutUser(requestData);
        dispatch(logout());
      } catch (error) {
        console.log("로그아웃 에러", error);
      }
      navigate("/auth/login");
      return;
    }
    console.log("userInfo", userInfo);
    e.preventDefault(); // 기본 링크 이동 방지
    // window.open(`https://partners-staging.classum.com/api/v1/auth/${ISNTITUTION_CODE}`, "_blank");
    navigate(`/about/consortium/${userInfo.cUnivNo}/mentoring`);
  };

  return (
    <li
      className={["depth1", isMobile || isTablet ? "event-click" : "event-hover"].join(" ")}
      key={headerItem?.to}
    >
      <h3>
        <a
          onClick={(e) => handleGnb(e, headerItem, idx)}
          href={headerItem?.to}
          title={headerItem.title}
        >
          {isMobile || isTablet ? headerItem.titleMobile || headerItem.title : headerItem.title}
        </a>
      </h3>

      {/* snb 클릭 idx에 따라 모바일에서 아코디언 메뉴 오픈 */}
      <ul className={["sub_list", accordion[idx] ? "open" : "hide"].join(" ")}>
        {headerItem.list.map((item, i) => (
          <li key={i} className={"depth2"}>
            {/* BMC의 경우 클래스네임 추가 */}
            <h4 className={headerItem.title === "창업교육 툴킷" ? "nav_bmc" : ""}>
              {item.title?.props?.children && item.title.props.children === "멘토링" ? (
                <a href="#" title={item.title.props.children} onClick={handleMentoringClick}>
                  {item.title}
                </a>
              ) : (
                <a
                  href={item.to}
                  title={
                    headerItem.title === "창업교육 툴킷" ? item.title.props.children : item.title
                  }
                  onClick={(e) => handleSub(e, item)}
                >
                  {item.title}
                </a>
              )}
            </h4>
          </li>
        ))}
      </ul>
    </li>
  );
};

export default React.memo(HeaderListItem);
