import { ReactComponent as CloseIcon } from "@/assets/svg/close_white.svg";

// * TopSubTitle 대신 사용 - 모달 헤더 부분
const ModalHeader = ({
  modalTitle = "모달 제목",
  onClose,
  includesClassName = "",
  theme = "",
  showCloseIcon,
}) => {
  return (
    <div className={`modal_header ${includesClassName} ${theme}`}>
      <div className={`modal_header_inner ${includesClassName} ${theme}`}>
        {/* 모달 타이틀 */}
        <div className={`modal_title line_clamp1 ${includesClassName} ${theme}`}>{modalTitle}</div>

        {/* close icon */}

        {showCloseIcon && (
          <CloseIcon
            width={"16px"}
            height={"16px"}
            className={`modal_close_button ${includesClassName}`}
            stroke="#000"
            onClick={onClose}
          />
        )}
      </div>
    </div>
  );
};

export default ModalHeader;
