import React, { useEffect, useState } from "react";
import "./AlarmModal.css";
import { ReactComponent as NotExistIcon } from "@/assets/svg/notExistIcon.svg";

const AlarmModal = ({ isOpen, onClose, onDelete, onMarkAsRead, memberNo, token, alarms }) => {
  if (!isOpen) return null;

  const unreadCount = alarms.filter((alarm) => !alarm.isRead).length;

  return (
    <div className="alarm-modal-overlay" onClick={onClose}>
      <div className="alarm-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="alarm-modal-header">
          <h2>
            알림
            {unreadCount > 0 && <span className="unread-count">{unreadCount}</span>}
          </h2>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="alarm-modal-body">
          {alarms.length > 0 ? (
            alarms.map((alarm) => (
              <div key={alarm.alarmNo} className={`alarm-item ${alarm.isRead ? "read" : "unread"}`} onClick={alarm.isRead ? undefined : () => onMarkAsRead(alarm.alarmNo)}>
                <div >
                  <p className="alarm_item_created_at">
                    {alarm.createdAt}
                    {!alarm.isRead && <span className="unread-dot"></span>}
                  </p>
                  <p className="alarm_item_message">
                    {alarm.alarmMessage
                      .replace(/\\n/g, "\n")
                      .split("\n")
                      .map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))}
                  </p>
                </div>
                <button
                  className="delete-button"
                  onClick={(e) => {
                    e.stopPropagation(); // 클릭 이벤트 전파 방지
                    onDelete(alarm.alarmNo);
                  }}
                  aria-label="삭제"
                >
                  삭제
                </button>
              </div>
            ))
          ) : (
            <div className="alarm_not_exist">
              <NotExistIcon />
              <div className="alarm_not_exist_text">
                <p>알림이 없습니다.</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AlarmModal;
