import store from "@/store";
import { login, logout, setAuth } from "@/store/modules/auth/authSlice";
import { getTokenCookies, removeTokenCookies } from "@/utils/cookies";
import axios from "axios";

/**@desc default axiosInstance
 * */
export function setInterceptors(instance) {
  // 요청 인터셉터
  instance.interceptors.request.use(
    function (config) {
      const { accessToken } = getTokenCookies();
      // 요청이 보내지기 전에 수행할 작업
      // console.log("store Token 확인", store.getState().auth.accessToken);
      config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : null;
      // config.headers.companyName = `${store.getters.cmpEngName}`;
      return config;
    },
    function (error) {
      // 요청 오류
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      const { accessToken, userInfo } = getTokenCookies();
      const authSlice = store.getState().authSlice;

      // ? 데이터를 성공적으로 받은 후 auth 데이터가 없다면 setState
      if (accessToken && userInfo && (!authSlice.accessToken || !authSlice.userInfo)) {
        store.dispatch(setAuth(accessToken));
      }
      // 2xx 범위 실행 코드
      // 응답 데이터 처리
      return response;
    },
    async function (error) {
      console.log("요청 error", error);
      // 2xx 범위를 벗어난 상태 코드에 대해서 실행
      const originalRequest = error.config;
      const cookies = getTokenCookies();

      if (!cookies.accessToken || !cookies.refreshToken || !cookies?.userInfo)
        return removeTokenCookies();

      const { cNo, cName, cFrom, cGubun } = cookies.userInfo;
      const { refreshToken, accessToken } = cookies;

      // origin axios instance

      // 엑세스토큰 401 시간만료 응답 오류 처리 ( 시간 만료 )
      if (error.response?.status === 401) {
        console.log("401 refresh error.response ", error.response);
        console.log("401 refresh error", error);
        // 리프래쉬 요청
        try {
          // 새로운 토큰 발급 요청
          const { data } = await axios.post(
            process.env.REACT_APP_API_URL_AUTH + "auth/refresh",
            { cNo, cName, cFrom, cGubun, refreshToken },
            { headers: { Authorization: `Bearer ${accessToken}` } },
          );
          console.log("리프래쉬 성공", data);
          // 발급 성공 시 로그인 처리
          store.dispatch(login({ ...data }));
          // 새로운 액세스 토큰으로 원래의 요청을 다시 시도
          originalRequest.headers.Authorization = `Bearer ${data?.data?.accessToken}`;
          return axios(originalRequest);
        } catch (refreshError) {
          // 토큰 발급 시 오류
          console.log("토큰 발급 시 오류", refreshError);
          // 만료된 토큰인 경우
          store.dispatch(logout());
          return Promise.reject(refreshError);
        }
      }
      // 유효 하지 않을 때 정보삭제, 로그인 페이지로 이동
      // 유저 정보 삭제
      if (error.response?.status === 403 && !location.pathname.includes("/mentoring")) {
        try {
          const { data } = await axios.post(
            process.env.REACT_APP_API_URL_AUTH + "auth/logout",
            { cNo, cName, cGubun, cFrom },
            { headers: { Authorization: `Bearer ${accessToken}` } },
          );
          console.log("로그아웃 403  interceptors", data);
        } catch (error) {
          console.log("403 token error", error);
        } finally {
          store.dispatch(logout());
        }
        return Promise.reject(originalRequest);
      }
      return Promise.reject(error);
    },
  );
  return instance;
}
