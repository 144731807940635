export const NOT_FOUND_MSG = "요청하신 페이지를 찾을 수 없어 초기페이지로 이동합니다.";
export const ROLE_MSG = "접근 권한이 없는 페이지입니다.";
export const LOADING_TXT = ["잠시만 기다려주세요.", "파일을 압축하여 안전하게 업로드중입니다."];

// ? 로컬호스트 환경 여부
export const IS_LOCALHOST = window.location.hostname === "localhost";

// todo: 엘라스틱서치 테스트 (추 후 삭제 예정)
export const ES_TEST_CERTIFICATE = "zxc123";

export const COMMON_MOTION_TRANSITION = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.2, ease: "easeInOut" },
};
