import { ComJavaApi } from "@/api";

/**
 * @description lms 교육 과정 소개 - 상세일정(모달)
 */
export function getConsortiumData() {
  return ComJavaApi.post("/consortiumdata");
}

export function getBoardNoticeList() {
  return ComJavaApi.post("/boardlistofuniversity");
}

export function getNewestProgram() {
  return ComJavaApi.post("/newestprogram");
}

export function getRecentlyContents() {
  return ComJavaApi.get("/getrecentlycontents");
}

export function getRecentlyProgram() {
  return ComJavaApi.get("/getrecentlyprogram");
}
