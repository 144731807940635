import React, { useState, useEffect } from "react";
import "./HomeThird.css";
import { useNavigate } from "react-router-dom";
import { consortiumData } from "@/mock/consortium-mock.js";
import mapIcon from "@/assets/svg/icon_map.svg";
import activeMapIcon from "@/assets/svg/icon_map_active.svg";
import GlobalTabSection from "@/components/common/Tab/GlobalTabSection";
import useResponsive from "@/hooks/useResponsive";
import MainUniversityConsortium from "./components/MainUniversityConsortium";

const MAP_IMAGE_LIST = {
  수도권: require("@/assets/images/home/capital_map.png"),
  충청권: require("@/assets/images/home/chungcheong.png"),
  동남권: require("@/assets/images/home/dongnam.png"),
  "호남 제주권": require("@/assets/images/home/honam_jeju.png"),
  "대경 강원권": require("@/assets/images/home/kangwon.png"),
};

const tabList = [
  {
    title: "일반대 컨소시엄",
  },
  {
    title: "전문대 컨소시엄",
  },
];
const HomeThird = () => {
  const navigate = useNavigate();
  const { isMobile } = useResponsive();

  const [selectedUniversity, setSelectedUniversity] = useState(consortiumData[0]);
  const [activeButton, setActiveButton] = useState(selectedUniversity.id);
  const [bgImage, setBgImage] = useState(MAP_IMAGE_LIST[selectedUniversity.map]);
  const [fade, setFade] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [animate, setAnimate] = useState(false);
  // 모바일
  const [universityTab, setUniversityTab] = useState("일반대 컨소시엄"); // 모바일 탭

  useEffect(() => {
    console.log("universityTab", universityTab);
  }, [universityTab]);

  const handleConsortiumClick = (data) => {
    if (activeButton === data.id) return;

    setSelectedUniversity(() => ({ ...data }));

    setFade(true); // 페이드 아웃 효과 시작
    setTimeout(() => {
      setActiveButton(data.id);
      setBgImage(MAP_IMAGE_LIST[data.map]);
      setTimeout(() => {
        setFade(false); // 페이드 인 효과 적용
      }, 100);
    }, 200);
  };

  const navigateToUniversity = (university) => {
    navigate(`/about/consortium/${university.univNo}/home`);
  };

  useEffect(() => {
    setAnimate(true);
    // 처음 렌더링 후 100ms 뒤에 fade-in 효과 적용
    setTimeout(() => {
      setIsInitialLoad(false);
    }, 100);
  }, []);

  return (
    <div className="home_third_wrapper">
      <div className="home_third_contents_wrapper">
        {/* 왼쪽 지도 */}
        <div
          className={`home_third_map_wrapper ${
            isInitialLoad ? "fade-in" : fade ? "fade-out" : "fade-in"
          }`}
          style={{
            backgroundImage: `url(${bgImage})`,
          }}
        >
          <div className="home_third_map_buttons">
            {consortiumData.map((data, idx) => (
              <div key={idx} className={`home_third_map_button ${data.class}`}>
                <img
                  src={activeButton === data.id ? activeMapIcon : mapIcon}
                  alt="map_icon"
                  className="home_third_map_icon"
                />
                <button
                  className={`${activeButton === data.id ? "active" : ""}`}
                  onClick={() => handleConsortiumClick(data)}
                >
                  {data.map}
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* 오른쪽 파트 */}
        <div className="home_third_university_info_wrapper" key={selectedUniversity.id}>
          {isMobile && (
            <div className="mobile">
              <GlobalTabSection
                tabList={tabList}
                selectedTab={universityTab}
                setSelectedTab={setUniversityTab}
              ></GlobalTabSection>
            </div>
          )}

          {!isMobile && (
            <div className="home_third_university_info">
              {/* 일반대 컨소시엄 */}
              <MainUniversityConsortium
                title="일반대 컨소시엄"
                isMobile={isMobile}
                universityList={selectedUniversity.universities.general}
                animate={!isMobile ? animate : false}
                navigateToUniversity={navigateToUniversity}
              />
              {/* 전문대 컨소시엄 */}
              <MainUniversityConsortium
                title="전문대 컨소시엄"
                isMobile={isMobile}
                universityList={selectedUniversity.universities.vocational}
                animate={!isMobile ? animate : false}
                navigateToUniversity={navigateToUniversity}
              />
            </div>
          )}

          {isMobile && (
            <div className="home_third_university_info">
              {/* 일반대 컨소시엄 */}
              {universityTab === "일반대 컨소시엄" && (
                <MainUniversityConsortium
                  title="일반대 컨소시엄"
                  isMobile={isMobile}
                  universityList={selectedUniversity.universities.general}
                  animate={!isMobile ? animate : false}
                  navigateToUniversity={navigateToUniversity}
                />
              )}

              {/* 전문대 컨소시엄 */}
              {universityTab === "전문대 컨소시엄" && (
                <MainUniversityConsortium
                  title="전문대 컨소시엄"
                  isMobile={isMobile}
                  universityList={selectedUniversity.universities.vocational}
                  animate={!isMobile ? animate : false}
                  navigateToUniversity={navigateToUniversity}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeThird;
