import "./HomeFirst.css";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "@/assets/svg/search_in_the_box.svg";

import Popup from "@/components/common/popup/Popup";
import Inspection from "@/components/common/inspection/Inspection";
import useResponsive from "@/hooks/useResponsive";

const HomeFirst = () => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");

  const navigateToSearch = (keyword) => {
    navigate(`/elastic?search=${keyword}`);
  };

  return (
    <div className="home_first_wrapper">
      <div className="home_first_container">
        <div className="home_first_content_text">
          <p>
            지역과 대학이 함께하는 창업 지원의 허브, SCOUT {!isMobile && <br />} 다양한 커리큘럼과
            실전 프로젝트가 준비된 창업교육 플랫폼입니다.
          </p>
        </div>

        {/* 하단 검색바 */}
        <div className="home_first_university_search_wrapper">
          <div className="home_first_university_search">
            <div className="home_first_university_search_input">
              <div className="home_first_university_search_title">
                <span>SEARCH</span> <span>통합검색</span>
              </div>
              <div className="home_first_main_total_input_wrapper_box">
                <input
                  type="text"
                  placeholder="검색어를 입력해주세요"
                  className="home_first_university_search_input_form"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      navigateToSearch(searchInput);
                    }
                  }}
                />
                <button onClick={() => navigateToSearch(searchInput)}>
                  <div className="home_first_search_icon">
                    <SearchIcon height={45} width={45} />
                  </div>
                </button>
              </div>
            </div>
            <div className="home_first_university_search_list">
              <div
                className="home_first_university_search_list_item"
                onClick={() => navigateToSearch("BMC")}
              >
                #BMC
              </div>
              <div
                className="home_first_university_search_list_item"
                onClick={() => navigateToSearch("스타트업")}
              >
                #스타트업
              </div>
              <div
                className="home_first_university_search_list_item"
                onClick={() => navigateToSearch("창업")}
              >
                #창업
              </div>
            </div>
          </div>
        </div>

        {/* <Popup
          cookiesName={"inspection_notice"}
          modalTitle={"점검 공지"}
          modalStyle={{ top: "50%", left: "50%" }}
        >
          <Inspection Inspection_time={"2025-3-7(금) 18:00 ~ 21:00"}></Inspection>
        </Popup> */}
      </div>
    </div>
  );
};

export default HomeFirst;
