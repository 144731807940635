import {
  SwiperPaginationClickStyle,
  SwiperPaginationNumberStyle,
} from "@/components/common/swiperPagination/SwiperCustomPagination";
import useResponsive from "@/hooks/useResponsive";
import CardContents from "@/pages/main/renewal/top-content/components/CardContents";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { Navigation, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import uuid from "react-uuid";

export default function MainPageProgram({ programCardData = [], handleOpenModal }) {
  const { isMobile, isTablet, isDesktop } = useResponsive();
  const swiperRef = useRef(null);

  const [currentCardPage, setCurrentCardPage] = useState(1);
  const [cards, setCards] = useState([]);

  const handlePaginationClick = (index) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
      setCurrentCardPage(index);
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const updateCurrentPage = (swiper) => {
    // `realIndex`로 현재 페이지를 계산
    const realIndex = swiper.realIndex;
    setCurrentCardPage(realIndex + 1);
  };

  useEffect(() => {
    if (!programCardData || programCardData.length === 0) return; // 데이터가 없으면 실행 안 함

    // 모바일은 3개 , pc는 5개로 slice
    const updatedCards = programCardData.slice(0, isMobile ? 3 : 5).map((program, index) => ({
      key: `card${index + 1}`,
      content: (
        <CardContents
          programData={program}
          className="card_box"
          title={program.programName}
          subTitle={program.eduType}
          description={program.edu_content}
          region={program.eduFields}
          button={false}
          showModal={true}
          handleOpenModal={() => handleOpenModal(program)}
        />
      ),
    }));

    setCards(updatedCards);
  }, [programCardData, isMobile]);

  useEffect(() => {
    console.log("props programs");
  }, [programCardData]);

  useEffect(() => {
    console.log("swiper cards ", cards);
  }, [cards]);

  return (
    <div className="home_second_programs">
      <div className="home_second_programs_top">
        <div className="home_second_programs_title">프로그램</div>
        {!isMobile && (
          <SwiperPaginationNumberStyle
            currentCardPage={currentCardPage}
            handleNext={handleNext}
            handlePrev={handlePrev}
            maxLength={cards?.length}
          />
        )}
      </div>
      <div className="home_second_programs_cards">
        {programCardData?.length > 0 && (
          <Swiper
            modules={[Navigation]}
            loop={true}
            breakpoints={{
              0: { slidesPerView: 1 },
              640: { slidesPerView: 2 },
              1280: { slidesPerView: 3 },
            }}
            slidesPerGroup={1}
            // loopedSlides={5}
            spaceBetween={30}
            onSwiper={(swiper) => {
              swiperRef.current = swiper; // Swiper 인스턴스를 참조에 저장
            }}
            onSlideChange={(swiper) => {
              updateCurrentPage(swiper);
            }}
          >
            {programCardData?.length > 0 &&
              cards?.map((card, idx) => (
                <SwiperSlide key={card.key + idx}>
                  <div className="home_second_program_card">{card.content}</div>
                </SwiperSlide>
              ))}
          </Swiper>
        )}
      </div>
      {isMobile && (
        <SwiperPaginationClickStyle
          item={cards}
          maxItems={3}
          currentCardPage={currentCardPage}
          handlePaginationClick={handlePaginationClick}
          isTextHide
        />
      )}
    </div>
  );
}
