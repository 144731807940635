import React from "react";

export const HEADER_LIST = [
  {
    title: "창업교육 혁신 선도대학(SCOUT)",
    titleMobile: "창업교육 혁신 선도대학",
    to: "/about/scout",
    list: [
      { title: "사업 소개", to: "/about/scout" },
      { title: "권역별 선도대학 소개", to: "/about/consortium" },
    ],
  },

  {
    title: "창업교육 프로그램",
    to: "/education/instruction",
    list: [
      { title: "프로그램 소개", to: "/education/instruction" },
      // { title: "프로그램 소개", to: "/education/course" },
      { title: "프로그램 신청/개설", to: "/education/list" },
      // { title: "멘토링", to: "/board/mentoring?p=1&myP=1" },
    ],
  },

  {
    title: "창업교육 툴킷",
    to: "/toolkit/bmc/instruction",
    list: [
      {
        title: <p>창업교육 툴킷 소개</p>,
        to: "/toolkit/bmc/instruction",
      },
      {
        title: (
          <p>
            BMC 작성
            <span>(비즈니스 모델 캔버스)</span>
          </p>
        ),
        to: "/toolkit/bmc/list",
      },
      {
        title: <p>사업계획서 작성</p>,
        to: "/toolkit/myBusiness",
      },
      {
        title: <p>AI 시장검증</p>,
        to: "/toolkit/chatbot",
      },
      {
        title: <p>멘토링</p>,
        to: "/board/notice?p=1",
      },
      // {
      //   title: <p>AI 프롬프트</p>,
      //   to: "/toolkit/chatbot",
      // },
    ],
  },

  {
    title: "지역 문제해결 허브",
    to: "/local/map",
    list: [{ title: "로컬실록지리지", to: "/local/map" }],
  },

  {
    title: "창업교육 콘텐츠",
    to: "/board/eduContents/researchRef?p=1",
    list: [
      { title: "수업 · 연구자료", to: "/board/eduContents/researchRef?p=1" },
      { title: "동영상 자료", to: "/board/eduContents/videoRef?p=1" },
      { title: "갤러리", to: "/board/eduContents/gallery?p=1" },
    ],
  },

  {
    title: "공지사항",
    to: "/board/notice?p=1",
    list: [
      { title: "공지사항", to: "/board/notice?p=1" },
      { title: "FAQ", to: "/board/FAQ?p=1" },
      { title: "1:1문의", to: "/board/inquiry?p=1" },
    ],
  },
  // {
  //   title: "멘토링",
  //   to: "/board/notice?p=1",
  //   list: [],
  // },
];
