import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // 데이터 캐싱
  cacheData: {},
  // 삭제할 채팅의 id를 담는 state
  deleteChatId: null,
  // 챗봇 로딩 state
  chatbotIsLoading: false,

  // 챗봇 컨펌모달 state
  chatbotIsOpenConfirm: false,
  // 사업계획서 모달 open 여부
  isBusinessPlanOpen: false,
  // 사업계획서 미리보기(상세보기) 모달 open 여부
  isBusinessPlanDetailOpen: false,
  // 사업계획서 선택 안내 모달 open 여부
  isSelectBusinessPlanNoticeConfirmOpen: false,

  // 메시지를 담는 배열 state
  messages: [],
  // 메시지 기록을 담는 배열 state
  recentChats: [],

  // 선택확정된 사업계획서 { bbiNo, bbiProjectName } 필수
  selectedBusinessPlan: null,
  // 미리보기 대상 사업계획서 { bbiNo, bbiProjectName } 필수
  previewTargetBusinessPlan: null,
};

const chatbotSlice = createSlice({
  name: "chatbotSlice",
  // 이니셜 스테이트
  initialState,

  reducers: {
    // ! states 리셋
    resetChatbotStates: () => {
      return initialState;
    },
    setCacheData: (state, { payload }) => {
      state.cacheData = payload;
    },
    setDeleteChatId: (state, { payload }) => {
      state.deleteChatId = payload;
    },
    setMessages: (state, { payload }) => {
      state.messages = payload;
    },
    setRecentChats: (state, { payload }) => {
      state.recentChats = payload;
    },
    setChatbotIsLoading: (state, { payload }) => {
      state.chatbotIsLoading = payload;
    },
    setChatbotIsOpenConfirm: (state, { payload }) => {
      state.chatbotIsOpenConfirm = payload;
    },
    setIsBusinessPlanOpen: (state, { payload }) => {
      state.isBusinessPlanOpen = payload;
    },
    setSelectedBusinessPlan: (state, { payload }) => {
      state.selectedBusinessPlan = payload;
    },
    setIsBusinessPlanDetailOpen: (state, { payload }) => {
      state.isBusinessPlanDetailOpen = payload;
    },
    setPreviewTargetBusinessPlan: (state, { payload }) => {
      state.previewTargetBusinessPlan = payload;
    },
    setIsSelectBusinessPlanNoticeConfirmOpen: (state, { payload }) => {
      state.isSelectBusinessPlanNoticeConfirmOpen = payload;
    },
  },
});

export const {
  resetChatbotStates,
  setChatbotIsLoading,
  setCacheData,
  setDeleteChatId,
  setMessages,
  setRecentChats,
  setChatbotIsOpenConfirm,
  setIsBusinessPlanOpen,
  setSelectedBusinessPlan,
  setIsBusinessPlanDetailOpen,
  setPreviewTargetBusinessPlan,
  setIsSelectBusinessPlanNoticeConfirmOpen,
} = chatbotSlice.actions;

export default chatbotSlice;
